export const environment = {
  production: true,
  enableServiceWorkers: true,
  enableAnimations: true,
  apiRoot: 'https://api.newfoundgroup.com',
  appRoot: 'https://app.newfoundgroup.com',
  hostName: 'https://newfoundgroup.com',
  websocketHostName: 'wss://api.newfoundgroup.com/',
  sellerCompletionEndpoint: 'https://houwzer.com/sell/thank-you',
  buyerCompletionEndpoint: 'https://houwzer.com/buy/thank-you',
  bothCompletionEndpoint: 'https://houwzer.com/bundle/thank-you',
  mortgageCompletionEndpoint: 'https://houwzer.com/mortgage/thank-you',
  refinanceCompletionEndpoint: 'https://houwzer.com/refinance/thank-you',
  hubspotBaseUrl: 'https://app.hubspot.com',
  hubspotPortalId: 20519632,
  recordAnalytics: true,
  airbrakeProjectId: 239179,
  airbrakeProjectKey: '46fa576ad657dcfd8ae1fb6f2de5ca67',
  googleMapsApiToken: 'AIzaSyBX-_sDRwLdA1Vmy98CggG0xo5tnjv9Kuk',
  dotloopClientId: '6f4a632a-8542-4ffc-924e-5ac57bc340ad',
  dotloopEnabled: true,
  socialAppIds: {
    facebook: '389870304748750',
    google: '403838857458-487c09749j108sito4aa5m6q89av4od9.apps.googleusercontent.com'
  }
};
